import { Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';
import React, { memo } from 'react';

import { MODAL_CONTENT_FULL_SCREEN_STYLES, MODAL_THEME_BLUE } from 'components/ModalContainers/constants';

import SecondaryDefaultButton from 'components/SecondaryDefaultButton';
import Modal from 'components/Modal';
import CancelModalNavBar from 'components/CancelModalNavBar';

import * as S from './styled';

import { ReactComponent as Heart } from 'assets/img/heart.svg';

import { IThankYouModal } from './types';

const ThankYouModal = ({ onSubmit }: IThankYouModal) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen fullscreen style={{ content: { ...MODAL_CONTENT_FULL_SCREEN_STYLES, ...MODAL_THEME_BLUE } }}>
            <CancelModalNavBar isFirstModal theme="blue" />
            <S.ThankYouContainer dataLocator="thankYouModalContainer">
                <S.Icon>
                    <Heart />
                </S.Icon>
                <Text className="lsp-m" type="h5" center mb={8} text={t('subscription.cancellation.thankYou.title')} />
                <Text className="lsp-s" type="medium-text" center>
                    <Trans i18nKey="subscription.cancellation.thankYou.subtitle" />
                </Text>
            </S.ThankYouContainer>
            <S.ButtonContainer>
                <SecondaryDefaultButton
                    text={t('basics.continue')}
                    onClick={onSubmit}
                    dataLocator="thankYouContinueButton"
                    className="ctaBtn ctaBtnWhite"
                />
            </S.ButtonContainer>
        </Modal>
    );
};

export default memo(ThankYouModal);
