import { selectSubscriptions } from 'store/subscriptions/selectors';
import { ModalName } from 'store/modals/types';

import { useScreenLoad } from 'services/analytics/useScreenLoad';
import { sendAnalyticSendFeedbackClick } from 'services/analytics';

import { useSelector, useCancelSubscription, useModals } from 'hooks';

import FeedbackModal from './components/FeedbackModal';

const FeedbackModalContainer = () => {
    const { cancellationCandidate } = useSelector(selectSubscriptions);

    const { onCancelSubscription, discardCancellation } = useCancelSubscription();

    const { openModal } = useModals();

    if (!cancellationCandidate) {
        return null;
    }

    useScreenLoad('cancel_feedback');

    const cancelSubscriptionHandler = () => {
        onCancelSubscription();
    };

    const sendFeedbackHandler = (value: string) => {
        sendAnalyticSendFeedbackClick(value);

        discardCancellation();

        openModal(ModalName.cancellationUpdatingModal, { type: 'feedback' });
    };

    return <FeedbackModal onCancelSubscription={cancelSubscriptionHandler} onSendFeedback={sendFeedbackHandler} />;
};

export default FeedbackModalContainer;
