// user
export interface UserData {
    year_of_birth: number | null;
    email: string;
    default_cycle_length: number | null;
    default_period_length: number | null;
    default_cycle_regularity: string | null;
}

export interface UserUpdateData extends Partial<UserData> {
    consent_mailing_active?: boolean;
    password?: string;
    name?: string;
}

export interface UserUpdateEmail {
    email: string;
}

export type ChangePasswordType = {
    old_password: string;
    new_password: string;
};

export interface IUpdateConsentMailing {
    data: UserUpdateData;
    nextPage: () => void;
}

export interface IUpdateUserInfoSaga {
    data: UserUpdateData;
    toNextPage: () => void;
    fieldError: (error: string) => void;
}

export interface SignUpDataPayLoad extends UserData {
    fitness_level: string;
    localization: string;
    target_zone: string[];
    units?: string;
    breakfast?: string;
    lunch?: string;
    dinner?: string;
}

export interface SignUpData {
    payload: SignUpDataPayLoad;
    setError?: (error: string) => void;
    toNextPage: () => void;
}

export interface UserInfoResponse extends UserData {
    user_id: number;
    token: string;
    name: null | string;
    last_name: null | string;
    is_paid: false;
    language: string;
    country: null | string;
    product_list: null | {
        [product: string]: boolean;
    };
    units?: string;
    localization: string;
    target_zone: string[];
    test_name?: string;
    ip_address: string;
    fitness_level: string;
    phone?: string;
}

export interface SignInRequestData {
    appsflyer_id: null;
    idfa: null;
    push_token: null;
}

export interface SignInData extends SignInRequestData {
    password: string;
    email: string;
}

// other
export interface WebTokenData {
    ott: string;
}

export interface DeepLinkData {
    url: string;
}

export interface HashedEmailResp {
    data: string;
}

export enum UnitTypes {
    Imperial = 'imperial',
    Metric = 'metric',
}

export interface UserWeightStatistic {
    date: string;
    created_at: string;
    weight: number;
    weight_source: string | null;
    weight_bundle_id: string | null;
}
