import { DISCOUNT_TYPES } from 'constants/subscriptions';

import {
    IDiscountSubscription,
    IPauseType,
    IResetSubscription,
    IRestoreSubscription,
    ISubscription,
    IUpdateSubscription,
} from 'types/subscription';

import Base from './Base';
import { CancelSubscriptionRequest } from '../types/request';
import { SubscriptionCancelResponse } from '../types/response';

class SubscriptionsApi extends Base {
    getSubscriptions = async <Response = ISubscription>(): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.get<Response>('user/subscription');
    };

    unsubscribe = async <Data = CancelSubscriptionRequest, Response = SubscriptionCancelResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<Data, Response>('solidgate/subscription/cancel', payload);
    };

    getDiscountSubscriptions = async <Response = IDiscountSubscription>(payload: {
        external_id: string;
        discount_type: DISCOUNT_TYPES;
    }): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<typeof payload, Response>('solidgate/subscription/discount', payload);
    };

    updateSubscription = async <Response = IDiscountSubscription>(
        payload: IUpdateSubscription
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<typeof payload, Response>('solidgate/subscription/switch', payload);
    };

    setReminder = async <Response = string>(payload: { external_id: string }): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<typeof payload, Response>('user/subscription/reminder', payload);
    };

    pauseSubscription = async <Response = string>(payload: {
        pause_type: IPauseType;
    }): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post('solidgate/subscription/pause', payload);
    };

    resumeSubscription = async <Response = string>(): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post('solidgate/subscription/resume', {});
    };

    resetSubscription = async <Response = string>(
        payload: IResetSubscription
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post('solidgate/subscription/reset', payload);
    };

    restoreSubscription = async <Response = string>(
        payload: IRestoreSubscription
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post('solidgate/subscription/restore', payload);
    };
}

export default SubscriptionsApi;
