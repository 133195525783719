import { ModalName } from 'store/modals/types';

export const VIP_SUPPORT = 'vip_support';
export const SPECIFIC_REASONS = [
    // {
    //     reasons: ['forget_to_cancel_later'],
    //     modal: ModalName.reminderOffer,
    // },
    // {
    //     reasons: ['too_expensive', 'other'],
    //     modal: ModalName.specialOfferModal,
    // },
    // {
    //     reasons: ['no_time', 'no_app_need'],
    //     modal: ModalName.pauseModal,
    // },
    // {
    //     reasons: ['disappointed_with_the_content'],
    //     modal: ModalName.guidesOfferModal,
    // },
    // {
    //     reasons: ['app_not_meets_expectations'],
    //     modal: ModalName.freeMonthOffer,
    // },
    {
        reasons: [
            'technical_issue',
            'didnt_know_autorenewal',
            'not_what_expected',
            'expensive',
            'no_longer_need',
            'purchased_by_mistake',
            'other',
        ],
        // modal: ModalName.thankYouModal,
        modal: ModalName.feedbackModal,
    },
];
