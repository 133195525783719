import { useScreenLoad } from 'services/analytics/useScreenLoad';

import { useCancelSubscription, useCancelSubscriptionModals } from 'hooks';

import CancellationReasonModal from './components/CancellationReasonModal';

const CancellationReasonModalContainer = () => {
    const { discardCancellation } = useCancelSubscription();
    const { cancelSubscriptionHandler } = useCancelSubscriptionModals();

    useScreenLoad('cancel_reason');

    return (
        <CancellationReasonModal
            onSubmit={cancelSubscriptionHandler}
            onDiscard={discardCancellation}
            onClose={discardCancellation}
        />
    );
};

export default CancellationReasonModalContainer;
