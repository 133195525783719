import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const FeedbackContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    max-width: 432px;
    padding: 32px 16px 16px;
    margin: 0 auto;
    height: calc(100% - 65px);
`;

export const FeedbackContent = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
`;

export const Title = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

export const Buttons = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
`;
