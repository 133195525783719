import { ModalName } from 'store/modals/types';

import { useScreenLoad } from 'services/analytics/useScreenLoad';

import { useModals } from 'hooks';

import ThankYouModal from './components/ThankYouModal';

const ThankYouModalContainer = () => {
    const { openModal } = useModals();

    useScreenLoad('cancel_feedback_thank_you');

    const onSubmit = () => {
        openModal(ModalName.interviewModal);
    };

    return <ThankYouModal onSubmit={onSubmit} />;
};

export default ThankYouModalContainer;
