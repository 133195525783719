import {
    DeepLinkData,
    SignInData,
    UserInfoResponse,
    UserUpdateData,
    ChangePasswordType,
    HashedEmailResp,
    UserWeightStatistic,
    UserUpdateEmail,
    WebTokenData,
} from 'types/user/userApiInterface';
import { IBlockById, IBlocks, ICategories } from 'types/insights';

import Base from './Base';

class UserApi extends Base {
    getUser = async <Response = UserInfoResponse>(): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.get<Response>('user/');
    };

    update = async <Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.patch<Data, Response>('user/', payload);
    };

    updateEmail = async <Data = UserUpdateEmail, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Response | unknown> => {
        return await this.apiClient.private.post<Data, Response>('change-email/', payload);
    };

    setUserGuidesOffer = async <Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Response | unknown> => {
        return await this.apiClient.private.post<Data, Response>('user/offers', payload);
    };

    changePassword = async <Data = ChangePasswordType, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<Data, Response>('user/password-change', payload);
    };

    signIn = async <Data = SignInData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.public.post<Data, Response>('email-sign-in/', payload);
    };

    getWebToken = async <Response = WebTokenData>(): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.get<Response>('auth/one-time-token');
    };

    resetPassword = async <Data = SignInData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.public.post<Data, Response>('send-reset-password-email', payload);
    };

    deepLinks = async <Response = DeepLinkData>(): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.get<Response>('user/auth-deeplink');
    };

    updateConsent = async <Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.post<Data, Response>('user/', payload);
    };

    getUserBySignature = async <Response = UserInfoResponse>(
        params: Record<string, string>
    ): Promise<Partial<Response> | unknown> => {
        return await this.apiClient.private.get<Response>(`core/user/${params.uId}/${params.signature}`);
    };

    hashedEmail = async () => {
        return await this.apiClient.private.get<HashedEmailResp>('front-hash');
    };

    getProducts = async ({ productCodes }: { productCodes?: string }) => {
        const productCodesQueryParam = productCodes ? `/?${productCodes}` : '';
        const url = `user/products${productCodesQueryParam}`;

        return await this.apiClient.private.get<HashedEmailResp>(url);
    };
    // block_limit=9 task required
    getInsights = async (limit = 9) => {
        return await this.apiClient.private.get<ICategories>(`insights/categories?block_limit=${limit}`);
    };

    getBlockByCategory = async (categoryId: string) => {
        return await this.apiClient.private.get<IBlocks>(`insights/blocks?category=${categoryId}`);
    };

    getNextBlockByCategory = async (params: { categoryId: string; pageNumber: number }) => {
        return await this.apiClient.private.get<IBlocks>(`insights/blocks?category=${params.categoryId}&page=${params.pageNumber}`);
    };

    getBlockPreview = async (blockId: number) => {
        return await this.apiClient.private.get<IBlockById>(`insights/blocks/${blockId}`);
    };

    getWeightStatistic = async (from: string) => {
        return await this.apiClient.private.get<{ items: UserWeightStatistic[] }>(`core/users/weights?from=${from}`);
    };

    deleteUser = async () => {
        return await this.apiClient.private.delete('user/', {});
    };
}

export default UserApi;
