import { useEffect, useMemo, useState } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';
import { ModalName } from 'store/modals/types';

import { sendAnalyticCancellationProgressScreenLoad } from 'services/analytics';

import { useModals, useSelector } from 'hooks';

import { ICancellationUpdatingModalContainerProps } from './components/CancellationUpdatingModal/types';
import CancellationUpdatingModal from './components/CancellationUpdatingModal';

const CancellationUpdatingModalContainer = ({ type, onSuccess }: ICancellationUpdatingModalContainerProps) => {
    const { openModal } = useModals();

    const { subscriptions } = useSelector(selectSubscriptions);
    const [isLoading, setIsLoading] = useState(true);

    const isCancelled = useMemo(() => subscriptions[0].internal_cancel_at, [subscriptions]);

    useEffect(() => {
        if (type === 'feedback') {
            sendAnalyticCancellationProgressScreenLoad('feedback_sent');
        }
        if (type === 'cancel') {
            sendAnalyticCancellationProgressScreenLoad('subscription_canceled');
        }
    }, []);

    useEffect(() => {
        if (!isCancelled) return;
        if (isLoading) return;

        setTimeout(() => {
            onSuccess && onSuccess();
        }, 1000);
    }, [isCancelled, isLoading]);

    const onSubmit = () => {
        setTimeout(() => {
            if (type === 'cancel') {
                setIsLoading(false);
                if (!isCancelled) return;

                onSuccess && onSuccess();
            }

            if (type === 'feedback') {
                openModal(ModalName.thankYouModal);
            }
        }, 1000);
    };

    return <CancellationUpdatingModal onSubmit={onSubmit} type={type} />;
};

export default CancellationUpdatingModalContainer;
