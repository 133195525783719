import { useTranslation } from 'react-i18next';

export const getConfig = () => {
    const { t } = useTranslation();

    return {
        title: t('subscription.cancelFlow.feedbackModal.title'),
        subtitle: t('subscription.cancelFlow.feedbackModal.subtitle'),
        placeholder: t('subscription.cancelFlow.feedbackModal.textarea.placeholder'),
        buttons: {
            sendFeedbackBtnText: t('subscription.text.sendFeedback'),
            cancelBtnText: t('subscription.text.cancel'),
        },
    };
};
